  .flag { 
    display: inline-block; 
    background-repeat: no-repeat;
  }

  .flag.flag-16 { 
      display: inline-block; 
      width: 16px;
      height: 16px;
      background-image: url('sprite-flags-16x16.png'); 
      background-repeat: no-repeat;
   }
.flag.flag-16.flag-abkhazia { background-position: -0px -0px; }
.flag.flag-16.flag-basque-country { background-position: -16px -0px; }
.flag.flag-16.flag-british-antarctic-territory { background-position: -32px -0px; }
.flag.flag-16.flag-commonwealth { background-position: -48px -0px; }
.flag.flag-16.flag-england { background-position: -64px -0px; }
.flag.flag-16.flag-gosquared { background-position: -80px -0px; }
.flag.flag-16.flag-kosovo { background-position: -96px -0px; }
.flag.flag-16.flag-mars { background-position: -112px -0px; }
.flag.flag-16.flag-nagorno-karabakh { background-position: -128px -0px; }
.flag.flag-16.flag-nato { background-position: -144px -0px; }
.flag.flag-16.flag-northern-cyprus { background-position: -160px -0px; }
.flag.flag-16.flag-olympics { background-position: -176px -0px; }
.flag.flag-16.flag-red-cross { background-position: -192px -0px; }
.flag.flag-16.flag-scotland { background-position: -208px -0px; }
.flag.flag-16.flag-somaliland { background-position: -224px -0px; }
.flag.flag-16.flag-south-ossetia { background-position: -240px -0px; }
.flag.flag-16.flag-united-nations { background-position: -256px -0px; }
.flag.flag-16.flag-unknown { background-position: -0px -16px; }
.flag.flag-16.flag-wales { background-position: -16px -16px; }
.flag.flag-16.flag-ad { background-position: -32px -16px; }
.flag.flag-16.flag-ae { background-position: -48px -16px; }
.flag.flag-16.flag-af { background-position: -64px -16px; }
.flag.flag-16.flag-ag { background-position: -80px -16px; }
.flag.flag-16.flag-ai { background-position: -96px -16px; }
.flag.flag-16.flag-al { background-position: -112px -16px; }
.flag.flag-16.flag-am { background-position: -128px -16px; }
.flag.flag-16.flag-an { background-position: -144px -16px; }
.flag.flag-16.flag-ao { background-position: -160px -16px; }
.flag.flag-16.flag-aq { background-position: -176px -16px; }
.flag.flag-16.flag-ar { background-position: -192px -16px; }
.flag.flag-16.flag-as { background-position: -208px -16px; }
.flag.flag-16.flag-at { background-position: -224px -16px; }
.flag.flag-16.flag-au { background-position: -240px -16px; }
.flag.flag-16.flag-aw { background-position: -256px -16px; }
.flag.flag-16.flag-ax { background-position: -0px -32px; }
.flag.flag-16.flag-az { background-position: -16px -32px; }
.flag.flag-16.flag-ba { background-position: -32px -32px; }
.flag.flag-16.flag-bb { background-position: -48px -32px; }
.flag.flag-16.flag-bd { background-position: -64px -32px; }
.flag.flag-16.flag-be { background-position: -80px -32px; }
.flag.flag-16.flag-bf { background-position: -96px -32px; }
.flag.flag-16.flag-bg { background-position: -112px -32px; }
.flag.flag-16.flag-bh { background-position: -128px -32px; }
.flag.flag-16.flag-bi { background-position: -144px -32px; }
.flag.flag-16.flag-bj { background-position: -160px -32px; }
.flag.flag-16.flag-bl { background-position: -176px -32px; }
.flag.flag-16.flag-bm { background-position: -192px -32px; }
.flag.flag-16.flag-bn { background-position: -208px -32px; }
.flag.flag-16.flag-bo { background-position: -224px -32px; }
.flag.flag-16.flag-br { background-position: -240px -32px; }
.flag.flag-16.flag-bs { background-position: -256px -32px; }
.flag.flag-16.flag-bt { background-position: -0px -48px; }
.flag.flag-16.flag-bw { background-position: -16px -48px; }
.flag.flag-16.flag-by { background-position: -32px -48px; }
.flag.flag-16.flag-bz { background-position: -48px -48px; }
.flag.flag-16.flag-ca { background-position: -64px -48px; }
.flag.flag-16.flag-cc { background-position: -80px -48px; }
.flag.flag-16.flag-cd { background-position: -96px -48px; }
.flag.flag-16.flag-cf { background-position: -112px -48px; }
.flag.flag-16.flag-cg { background-position: -128px -48px; }
.flag.flag-16.flag-ch { background-position: -144px -48px; }
.flag.flag-16.flag-ci { background-position: -160px -48px; }
.flag.flag-16.flag-ck { background-position: -176px -48px; }
.flag.flag-16.flag-cl { background-position: -192px -48px; }
.flag.flag-16.flag-cm { background-position: -208px -48px; }
.flag.flag-16.flag-cn { background-position: -224px -48px; }
.flag.flag-16.flag-co { background-position: -240px -48px; }
.flag.flag-16.flag-cr { background-position: -256px -48px; }
.flag.flag-16.flag-cu { background-position: -0px -64px; }
.flag.flag-16.flag-cv { background-position: -16px -64px; }
.flag.flag-16.flag-cw { background-position: -32px -64px; }
.flag.flag-16.flag-cx { background-position: -48px -64px; }
.flag.flag-16.flag-cy { background-position: -64px -64px; }
.flag.flag-16.flag-cz { background-position: -80px -64px; }
.flag.flag-16.flag-de { background-position: -96px -64px; }
.flag.flag-16.flag-dj { background-position: -112px -64px; }
.flag.flag-16.flag-dk { background-position: -128px -64px; }
.flag.flag-16.flag-dm { background-position: -144px -64px; }
.flag.flag-16.flag-do { background-position: -160px -64px; }
.flag.flag-16.flag-dz { background-position: -176px -64px; }
.flag.flag-16.flag-ec { background-position: -192px -64px; }
.flag.flag-16.flag-ee { background-position: -208px -64px; }
.flag.flag-16.flag-eg { background-position: -224px -64px; }
.flag.flag-16.flag-eh { background-position: -240px -64px; }
.flag.flag-16.flag-er { background-position: -256px -64px; }
.flag.flag-16.flag-es { background-position: -0px -80px; }
.flag.flag-16.flag-et { background-position: -16px -80px; }
.flag.flag-16.flag-eu { background-position: -32px -80px; }
.flag.flag-16.flag-fi { background-position: -48px -80px; }
.flag.flag-16.flag-fj { background-position: -64px -80px; }
.flag.flag-16.flag-fk { background-position: -80px -80px; }
.flag.flag-16.flag-fm { background-position: -96px -80px; }
.flag.flag-16.flag-fo { background-position: -112px -80px; }
.flag.flag-16.flag-fr { background-position: -128px -80px; }
.flag.flag-16.flag-ga { background-position: -144px -80px; }
.flag.flag-16.flag-gb { background-position: -160px -80px; }
.flag.flag-16.flag-gd { background-position: -176px -80px; }
.flag.flag-16.flag-ge { background-position: -192px -80px; }
.flag.flag-16.flag-gg { background-position: -208px -80px; }
.flag.flag-16.flag-gh { background-position: -224px -80px; }
.flag.flag-16.flag-gi { background-position: -240px -80px; }
.flag.flag-16.flag-gl { background-position: -256px -80px; }
.flag.flag-16.flag-gm { background-position: -0px -96px; }
.flag.flag-16.flag-gn { background-position: -16px -96px; }
.flag.flag-16.flag-gq { background-position: -32px -96px; }
.flag.flag-16.flag-gr { background-position: -48px -96px; }
.flag.flag-16.flag-gs { background-position: -64px -96px; }
.flag.flag-16.flag-gt { background-position: -80px -96px; }
.flag.flag-16.flag-gu { background-position: -96px -96px; }
.flag.flag-16.flag-gw { background-position: -112px -96px; }
.flag.flag-16.flag-gy { background-position: -128px -96px; }
.flag.flag-16.flag-hk { background-position: -144px -96px; }
.flag.flag-16.flag-hn { background-position: -160px -96px; }
.flag.flag-16.flag-hr { background-position: -176px -96px; }
.flag.flag-16.flag-ht { background-position: -192px -96px; }
.flag.flag-16.flag-hu { background-position: -208px -96px; }
.flag.flag-16.flag-ic { background-position: -224px -96px; }
.flag.flag-16.flag-id { background-position: -240px -96px; }
.flag.flag-16.flag-ie { background-position: -256px -96px; }
.flag.flag-16.flag-il { background-position: -0px -112px; }
.flag.flag-16.flag-im { background-position: -16px -112px; }
.flag.flag-16.flag-in { background-position: -32px -112px; }
.flag.flag-16.flag-iq { background-position: -48px -112px; }
.flag.flag-16.flag-ir { background-position: -64px -112px; }
.flag.flag-16.flag-is { background-position: -80px -112px; }
.flag.flag-16.flag-it { background-position: -96px -112px; }
.flag.flag-16.flag-je { background-position: -112px -112px; }
.flag.flag-16.flag-jm { background-position: -128px -112px; }
.flag.flag-16.flag-jo { background-position: -144px -112px; }
.flag.flag-16.flag-jp { background-position: -160px -112px; }
.flag.flag-16.flag-ke { background-position: -176px -112px; }
.flag.flag-16.flag-kg { background-position: -192px -112px; }
.flag.flag-16.flag-kh { background-position: -208px -112px; }
.flag.flag-16.flag-ki { background-position: -224px -112px; }
.flag.flag-16.flag-km { background-position: -240px -112px; }
.flag.flag-16.flag-kn { background-position: -256px -112px; }
.flag.flag-16.flag-kp { background-position: -0px -128px; }
.flag.flag-16.flag-kr { background-position: -16px -128px; }
.flag.flag-16.flag-kw { background-position: -32px -128px; }
.flag.flag-16.flag-ky { background-position: -48px -128px; }
.flag.flag-16.flag-kz { background-position: -64px -128px; }
.flag.flag-16.flag-la { background-position: -80px -128px; }
.flag.flag-16.flag-lb { background-position: -96px -128px; }
.flag.flag-16.flag-lc { background-position: -112px -128px; }
.flag.flag-16.flag-li { background-position: -128px -128px; }
.flag.flag-16.flag-lk { background-position: -144px -128px; }
.flag.flag-16.flag-lr { background-position: -160px -128px; }
.flag.flag-16.flag-ls { background-position: -176px -128px; }
.flag.flag-16.flag-lt { background-position: -192px -128px; }
.flag.flag-16.flag-lu { background-position: -208px -128px; }
.flag.flag-16.flag-lv { background-position: -224px -128px; }
.flag.flag-16.flag-ly { background-position: -240px -128px; }
.flag.flag-16.flag-ma { background-position: -256px -128px; }
.flag.flag-16.flag-mc { background-position: -0px -144px; }
.flag.flag-16.flag-md { background-position: -16px -144px; }
.flag.flag-16.flag-me { background-position: -32px -144px; }
.flag.flag-16.flag-mf { background-position: -48px -144px; }
.flag.flag-16.flag-mg { background-position: -64px -144px; }
.flag.flag-16.flag-mh { background-position: -80px -144px; }
.flag.flag-16.flag-mk { background-position: -96px -144px; }
.flag.flag-16.flag-ml { background-position: -112px -144px; }
.flag.flag-16.flag-mm { background-position: -128px -144px; }
.flag.flag-16.flag-mn { background-position: -144px -144px; }
.flag.flag-16.flag-mo { background-position: -160px -144px; }
.flag.flag-16.flag-mp { background-position: -176px -144px; }
.flag.flag-16.flag-mq { background-position: -192px -144px; }
.flag.flag-16.flag-mr { background-position: -208px -144px; }
.flag.flag-16.flag-ms { background-position: -224px -144px; }
.flag.flag-16.flag-mt { background-position: -240px -144px; }
.flag.flag-16.flag-mu { background-position: -256px -144px; }
.flag.flag-16.flag-mv { background-position: -0px -160px; }
.flag.flag-16.flag-mw { background-position: -16px -160px; }
.flag.flag-16.flag-mx { background-position: -32px -160px; }
.flag.flag-16.flag-my { background-position: -48px -160px; }
.flag.flag-16.flag-mz { background-position: -64px -160px; }
.flag.flag-16.flag-na { background-position: -80px -160px; }
.flag.flag-16.flag-nc { background-position: -96px -160px; }
.flag.flag-16.flag-ne { background-position: -112px -160px; }
.flag.flag-16.flag-nf { background-position: -128px -160px; }
.flag.flag-16.flag-ng { background-position: -144px -160px; }
.flag.flag-16.flag-ni { background-position: -160px -160px; }
.flag.flag-16.flag-nl { background-position: -176px -160px; }
.flag.flag-16.flag-no { background-position: -192px -160px; }
.flag.flag-16.flag-np { background-position: -208px -160px; }
.flag.flag-16.flag-nr { background-position: -224px -160px; }
.flag.flag-16.flag-nu { background-position: -240px -160px; }
.flag.flag-16.flag-nz { background-position: -256px -160px; }
.flag.flag-16.flag-om { background-position: -0px -176px; }
.flag.flag-16.flag-pa { background-position: -16px -176px; }
.flag.flag-16.flag-pe { background-position: -32px -176px; }
.flag.flag-16.flag-pf { background-position: -48px -176px; }
.flag.flag-16.flag-pg { background-position: -64px -176px; }
.flag.flag-16.flag-ph { background-position: -80px -176px; }
.flag.flag-16.flag-pirate-black { background-position: -96px -176px; }
.flag.flag-16.flag-pirate-white { background-position: -112px -176px; }
.flag.flag-16.flag-pk { background-position: -128px -176px; }
.flag.flag-16.flag-pl { background-position: -144px -176px; }
.flag.flag-16.flag-pn { background-position: -160px -176px; }
.flag.flag-16.flag-pr { background-position: -176px -176px; }
.flag.flag-16.flag-ps { background-position: -192px -176px; }
.flag.flag-16.flag-pt { background-position: -208px -176px; }
.flag.flag-16.flag-pw { background-position: -224px -176px; }
.flag.flag-16.flag-py { background-position: -240px -176px; }
.flag.flag-16.flag-qa { background-position: -256px -176px; }
.flag.flag-16.flag-ro { background-position: -0px -192px; }
.flag.flag-16.flag-rs { background-position: -16px -192px; }
.flag.flag-16.flag-ru { background-position: -32px -192px; }
.flag.flag-16.flag-rw { background-position: -48px -192px; }
.flag.flag-16.flag-sa { background-position: -64px -192px; }
.flag.flag-16.flag-sb { background-position: -80px -192px; }
.flag.flag-16.flag-sc { background-position: -96px -192px; }
.flag.flag-16.flag-sd { background-position: -112px -192px; }
.flag.flag-16.flag-se { background-position: -128px -192px; }
.flag.flag-16.flag-sg { background-position: -144px -192px; }
.flag.flag-16.flag-sh { background-position: -160px -192px; }
.flag.flag-16.flag-si { background-position: -176px -192px; }
.flag.flag-16.flag-sk { background-position: -192px -192px; }
.flag.flag-16.flag-sl { background-position: -208px -192px; }
.flag.flag-16.flag-sm { background-position: -224px -192px; }
.flag.flag-16.flag-sn { background-position: -240px -192px; }
.flag.flag-16.flag-so { background-position: -256px -192px; }
.flag.flag-16.flag-sr { background-position: -0px -208px; }
.flag.flag-16.flag-ss { background-position: -16px -208px; }
.flag.flag-16.flag-st { background-position: -32px -208px; }
.flag.flag-16.flag-sv { background-position: -48px -208px; }
.flag.flag-16.flag-sy { background-position: -64px -208px; }
.flag.flag-16.flag-sz { background-position: -80px -208px; }
.flag.flag-16.flag-tc { background-position: -96px -208px; }
.flag.flag-16.flag-td { background-position: -112px -208px; }
.flag.flag-16.flag-tf { background-position: -128px -208px; }
.flag.flag-16.flag-tg { background-position: -144px -208px; }
.flag.flag-16.flag-th { background-position: -160px -208px; }
.flag.flag-16.flag-tj { background-position: -176px -208px; }
.flag.flag-16.flag-tk { background-position: -192px -208px; }
.flag.flag-16.flag-tl { background-position: -208px -208px; }
.flag.flag-16.flag-tm { background-position: -224px -208px; }
.flag.flag-16.flag-tn { background-position: -240px -208px; }
.flag.flag-16.flag-to { background-position: -256px -208px; }
.flag.flag-16.flag-tr { background-position: -0px -224px; }
.flag.flag-16.flag-tt { background-position: -16px -224px; }
.flag.flag-16.flag-tv { background-position: -32px -224px; }
.flag.flag-16.flag-tw { background-position: -48px -224px; }
.flag.flag-16.flag-tz { background-position: -64px -224px; }
.flag.flag-16.flag-ua { background-position: -80px -224px; }
.flag.flag-16.flag-ug { background-position: -96px -224px; }
.flag.flag-16.flag-us { background-position: -112px -224px; }
.flag.flag-16.flag-uy { background-position: -128px -224px; }
.flag.flag-16.flag-uz { background-position: -144px -224px; }
.flag.flag-16.flag-va { background-position: -160px -224px; }
.flag.flag-16.flag-vc { background-position: -176px -224px; }
.flag.flag-16.flag-ve { background-position: -192px -224px; }
.flag.flag-16.flag-vg { background-position: -208px -224px; }
.flag.flag-16.flag-vi { background-position: -224px -224px; }
.flag.flag-16.flag-vn { background-position: -240px -224px; }
.flag.flag-16.flag-vu { background-position: -256px -224px; }
